<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff; line-height: 1.5;" v-for="item in approvals" :key="item.id">
					<div>No.{{item.sp_no}} <van-tag type="primary">{{statuses[item.status]}}</van-tag></div>
					<div style="font-size: 12px; color: #666;" v-if="item.approval_template.oa_type === 'App\\Models\\WochatApp'">企业微信【{{item.approval_template.template_id}}】</div>
					<div style="font-size: 12px; color: #666;" v-if="item.approval_template.oa_type === 'App\\Models\\DingtalkApp'">钉钉【{{item.approval_template.template_id}}】</div>
					<div style="font-size: 12px; color: #666;" v-if="item.common_model_type === 'App\\Models\\Contract'">No.{{item.common_model.no}} 合同金额 {{item.common_model.price/100}} 元</div>
					<div style="font-size: 12px; color: #666;" v-if="item.common_model_type === 'App\\Models\\ContractFee'">No.{{item.common_model.no}} 回款金额 {{item.common_model.price/100}} 元</div>
					<div v-if="item.created_at">提交于 {{item.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getApprovals({ ...params, page: v }) }"></van-pagination>
		</template>
		<main-table v-else>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="请输入流水" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.oa_type" placeholder="OA" filterable clearable>
						<el-option v-for="(type, t) in oa_types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.model_type" placeholder="内容" filterable clearable>
						<el-option v-for="(type, t) in model_types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="状态" filterable clearable>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getApprovals({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="approvals" :size="theme.size">
				<el-table-column label="编码" prop="sp_no" min-width="120"></el-table-column>
				<el-table-column label="OA" min-width="80" :formatter="({approval_template}) => { return oa_types[approval_template.oa_type] || '自建审核'; }"></el-table-column>
				<el-table-column label="模板" prop="approval_template.template_id" min-width="320"></el-table-column>
				<el-table-column label="类型" min-width="100" :formatter="({common_model_type}) => { return model_types[common_model_type] || '未知'; }"></el-table-column>
				<el-table-column label="流水" prop="common_model.no" min-width="180"></el-table-column>
				<el-table-column label="状态" prop="status" min-width="80" :formatter="(r) => { return statuses[r.status]; }"></el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="160" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="160" v-if="theme.width >= 1080"></el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getApprovals({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getApprovals({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async getApprovals (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_APPROVALS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.approvals = result.data;
				this.statuses = result.statuses;
				this.oa_types = result.oa_types;
				this.model_types = result.model_types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				model_types: [],
				oa_types: [],
				statuses: [],
				approvals: [],
				params: {
					perPage: 10,
					keywords: '',
				}
			}
		},
		async created () {
			this.getApprovals(this.params, true);
		}
	};
</script>